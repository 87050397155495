import type { BankListDto } from '@pickthebank/api-sdk/type/response/entity/bank/bank--list.dto';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';

import { Container } from '@/components';
import { COUNTRY_NAMES } from '@/store/storeSearch.consts';
import { COUNTRIES_PATHS } from '@/utils/countries';
import { termKeys } from '@/utils/termKeys';

const TERMS = [null, 6, 12, 24, 36];
const COUNTRIES = [
  'Germany',
  'Italy',
  'Netherlands',
  'Austria',
  'Spain',
  'Ireland',
];

export const LinkingList = ({ banks }: { banks: BankListDto[] }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const renderFixedDeposits = () => {
    return COUNTRIES.map((country, countryIndex) =>
      TERMS.map((term, termIndex) => (
        <li key={`${countryIndex}-${termIndex}`}>
          {term === null ? (
            <Link
              className="block truncate text-[15px] tracking-[0.2px] text-grey-800"
              href={`/fixed-deposits/${COUNTRIES_PATHS[country][locale || 'en']}/`}
            >
              {t('linking.fixed-deposits.item-basic', {
                country: t(`database.countries.${country}`),
              })}
            </Link>
          ) : (
            <Link
              className="block truncate text-[15px] tracking-[0.2px] text-grey-800"
              href={`/fixed-deposits/${COUNTRIES_PATHS[country][locale || 'en']}/${termKeys[term][locale || 'en']}/eur`}
            >
              {t('linking.fixed-deposits.item', {
                country: t(`database.countries.${country}`),
                term,
              })}
            </Link>
          )}
        </li>
      )),
    );
  };

  const renderSavingsAccounts = () => {
    return [...COUNTRY_NAMES].map((country) => {
      return (
        <li key={country}>
          <Link
            className="block truncate text-[15px] tracking-[0.2px] text-grey-800"
            href={`/savings-accounts/${COUNTRIES_PATHS[country][locale || 'en']}`}
          >
            {t('linking.savings-accounts.item', {
              country: t(`database.countries.${country}`),
            })}
          </Link>
        </li>
      );
    });
  };
  const groupBanksByCountry = () => {
    return banks
      .filter((bank) => {
        return !!bank?.country?.name && !!bank?.slug;
      })
      .reduce(
        (acc, bank) => {
          if (!acc[bank.country.name]) {
            acc[bank.country.name] = [];
          }
          acc[bank.country.name]!.push(bank);
          return acc;
        },
        {} as Record<string, BankListDto[]>,
      );
  };

  const renderBanks = () => {
    const preparedBanks = groupBanksByCountry();
    return Object.entries(preparedBanks).map(
      ([country, banksList]: [country: string, bankList: BankListDto[]]) => {
        return (
          <div key={country}>
            <h3 className="h6 mt-8">
              {t('linking.banks.subtitle', {
                country: t(`database.countries.${country}`),
              })}
            </h3>
            <ul className="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 lg:grid-cols-3">
              {banksList.map((bank: BankListDto) => (
                <li key={bank.index}>
                  <Link
                    className="block truncate text-[15px] tracking-[0.2px] text-grey-800"
                    href={`/banks/${COUNTRIES_PATHS[country][locale || 'en']}/${bank.slug}`}
                    title={bank.name}
                  >
                    {bank.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        );
      },
    );
  };

  return (
    <div className="mt-16">
      <Container>
        <div>
          <h2 className="h4 sm:h3">{t('linking.fixed-deposits.title')}</h2>
          <ul className="mt-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-12 lg:grid-cols-3 lg:grid-rows-8">
            {renderFixedDeposits()}
          </ul>
        </div>
        <div className="mt-16">
          <h2 className="h4 sm:h3">{t('linking.savings-accounts.title')}</h2>
          <ul className="mt-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-15 lg:grid-cols-3 lg:grid-rows-10">
            {renderSavingsAccounts()}
          </ul>
        </div>
        <div className="mt-16">
          <h2 className="h4 sm:h3">{t('linking.banks.title')}</h2>
          {renderBanks()}
        </div>
      </Container>
    </div>
  );
};
