import type { BankListDto } from '@pickthebank/api-sdk/type/response/entity/bank/bank--list.dto';
import type { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import { LinkingList } from '@/components/LinkingList';
import { banks as BANKS_LIST } from '@/constants/mainPageBanks.json';
import { useBestOffersModal } from '@/hooks/useBestOffersModal';
import { HomeLayout } from '@/layouts';
import { Meta } from '@/layouts/Meta';
import { MapDepositsFromServer } from '@/services/supabase';
import { Main } from '@/templates/Main';
import type { IBankDepositCard } from '@/types';

import ApiSingleton from '../../services/ApiSingleton';

const Home = ({
  topDeposits,
  banks,
}: {
  topDeposits: IBankDepositCard[];
  banks: BankListDto[];
}) => {
  const { t } = useTranslation();
  useBestOffersModal();
  return (
    <Main
      shouldHeaderBeBlue
      meta={
        <Meta
          title={t('pages.home.title')}
          description={t('pages.home.description')}
          keywords={t('pages.home.keywords')}
        />
      }
    >
      <HomeLayout topDeposits={topDeposits} />
      <LinkingList banks={banks} />
    </Main>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const apiSingleton = ApiSingleton.getInstance();
  let topDeposits = [];
  const { data: depositsData } = await apiSingleton.getDepositsListRequest(
    locale || 'en',
    {
      page: 1,
      deposit_types: ['ordinary deposit'],
      product_types: ['fixed term deposit'],
      terms: [12],
      currencies: ['eur'],
      is_partner: true,
    },
  );
  const { data: banks } = await apiSingleton.getBanksListRequest(
    locale || 'en',
    {
      page: 1,
      limit: 200,
      only: {
        index: BANKS_LIST,
      },
    },
  );
  topDeposits = [...depositsData];
  if (topDeposits.length > 4) {
    topDeposits = topDeposits.splice(0, 4);
  }
  const preparedTopDeposits: IBankDepositCard[] = topDeposits?.map((deposit) =>
    MapDepositsFromServer(deposit),
  )!;
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
      topDeposits: preparedTopDeposits,
      banks,
    },
  };
};
export default Home;
